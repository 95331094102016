import { TableColumnConfigurable } from '../../../../core/modals/table.modal';
import { DataCellSize, DataCellType } from '../../../../shared/dto/table.dto';
import { MoreItems } from '../../../../shared/models/more-dropdown.config';

export const complianceListQuickFilters: any[] = [
  {
    label: 'All',
    key: '',
    isActive: true,
  },
  {
    label: 'Compliant',
    key: 'compliant',
    isActive: false,
  },
  {
    label: 'Partially Compliant',
    key: 'partially_compliant',
    isActive: false,
  },
  {
    label: 'Non Compliant',
    key: 'non_compliant',
    isActive: false,
  },
  // {
  //   label: 'Archived',
  //   key: 'archived',
  //   isActive: false,
  // },
];

export const complianceListMoreItemsConfig: MoreItems[] = [
  // 'template',
  // 'import',
  'export',
  // 'archive',
  // 'delete',
  'refresh',
];

export const complianceListTablecolumns: TableColumnConfigurable[] = [
  {
    type: DataCellType.Text,
    field: 'reference_number',
    header: 'Reference Number',
    sortField: 'reference_number',
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Text,
    field: 'standard_code',
    header: 'Standard Code',
    sortField: 'standard_code',
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'title',
    header: 'Title',
    sortable: true,
    sortField: 'title',
    translation: true,
    size: DataCellSize.Title,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Para,
    field: 'description',
    header: 'Description',
    limit: 200,
    size: DataCellSize.Para,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Text,
    field: 'compliance_category_title',
    header: 'Category',
    sortField: 'category_title',
    translation: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.PrimaryUnit,
    field: 'responsible_unit_title',
    header: 'Unit (TBD)',
    sortField: 'responsible_unit',
    translation: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.User,
    field: 'responsible_user',
    header: 'Responsible User',
    isPrimaryLevel: true,
    sortField: 'responsible_user',
    translation: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Text,
    field: 'review_frequency_title',
    header: 'Review Frequency',
    sortField: 'review_frequency_title',
    translation: true,
    size: DataCellSize.Category,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.User,
    field: 'review_responsible_user',
    header: 'Review Responsible User',
    sortField: 'review_responsible_user',
    translation: true,
    size: DataCellSize.User,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'serverity_level',
    header: 'Severity Level',
    sortField: 'severity_level',
    translation: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Datetime,
    field: 'deadline',
    header: 'Deadline',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.Datetime,
    field: 'issue_date',
    header: 'Issue Date',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Datetime,
    field: 'expiry_date',
    header: 'Expiry Date',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.Datetime,
    field: 'next_review_date',
    header: 'Next Review Date',
    size: DataCellSize.Date,
    isEnable: true,
    isRequired: false,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'compliance_status',
    header: 'Compliance Status',
    sortField: 'compliance_status',
    translation: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: true,
  },
  {
    type: DataCellType.ColorLabel,
    field: 'lifecycle_status',
    header: 'Status',
    sortField: 'status',
    translation: true,
    size: DataCellSize.colorLabel,
    isEnable: true,
    isRequired: false,
  },
];
