import { Injectable } from '@angular/core';
import { ComplianceListDTO } from '../dto/compliance.dto';
import {
  FilterCategory,
  QuickFilterItem,
  TableActionConfig,
  TableColumnConfigurable,
  createFilterCategory,
  defaultActionConfig,
} from '../../../../core/modals/table.modal';
import { ComplianceService } from '../services/compliance.service';
import { catchError, forkJoin, tap } from 'rxjs';
import { runInAction } from 'mobx';
import { SeverityLevelService } from '../../../settings/masters/services/severity-level.service';
import { ListBaseStore } from '../../../../core/store/list-base.store';
import { SeverityLevelDTO } from '../../../settings/masters/dto/severity-level.dto';
import { ComplianceCategoryDTO } from '../../../settings/compliance/dto/compliance-category.dto';
import { ComplianceStatusDTO } from '../dto/compliance-status.dto';
import { UserDTO, UserListDTO } from '../../../organization/users/dto/user.dto';
import { ReviewFrequencyDTO } from '../../../settings/masters/dto/review-frequency.dto';
import { ComplianceCategoryService } from '../../../settings/compliance/services/compliance-category.service';
import { ComplianceStatusService } from '../../../settings/compliance/services/compliance-status.service';
import { ReviewFrequencyService } from '../../../settings/masters/services/review-frequency.service';
import { UserService } from '../../../organization/users/services/user.service';
import { ComplianceReviewStatusDTO } from '../../../settings/compliance/dto/compliance-review-status.dto';
import { ComplianceReviewStatusService } from '../../../settings/compliance/services/compliance-review-status.service';
import { ComplinaceConversion } from '../conversion/compliance.conversion';
import {
  complianceListMoreItemsConfig,
  complianceListQuickFilters,
  complianceListTablecolumns,
} from '../config/compliance-list.config';
import { MoreItems } from '../../../../shared/models/more-dropdown.config';
import { ConfirmService } from '../../../../core/services/confirm.service';
import { AlertService } from '../../../../core/services/alert.service';
import { SideMenuStore } from '../../../../core/store/side-menu.store';

@Injectable({ providedIn: 'root' })
export class ComplianceStore extends ListBaseStore<ComplianceListDTO> {
  module:string = '';
  subModule:string = '';
  sortField: string = '';
  columns: TableColumnConfigurable[] = [];
  quickFilters: QuickFilterItem[] = [];
  filterCategories: FilterCategory[] = [];
  severityLevels: SeverityLevelDTO[] = [];
  categories: ComplianceCategoryDTO[] = [];
  complianceStatuses: ComplianceStatusDTO[] = [];
  complianceReviewStatuses: ComplianceReviewStatusDTO[] = [];
  // responsibleUnits: OrganizationUnitDTO[];
  responsibleUsers: UserListDTO[] = [];
  reviewFrequencies: ReviewFrequencyDTO[] = [];
  reviewResponsibleUsers: UserDTO[] = [];
  moreItemsConfig:MoreItems[] = [];
  actionConfig: TableActionConfig | undefined = undefined

  constructor(
    private complianceService: ComplianceService,
    private severityLevelService: SeverityLevelService,
    private complianceCategoryService: ComplianceCategoryService,
    private complianceStatusService: ComplianceStatusService,
    private complianceReviewStatusService: ComplianceReviewStatusService,
    private reviewFrequencyService: ReviewFrequencyService,
    private userService: UserService,
    private complinaceConversion: ComplinaceConversion,
    protected override  confirmService: ConfirmService,
    protected override alertService: AlertService,
    private sideMenuStore: SideMenuStore 
  ) {
    super();
  }

  get service() {
    return this.complianceService;
  }

  override initialize(): void {
    this.module='compliance';
    this.subModule='compliance_register';
    this.exportFileName = 'Compliance_List';
    this.sortField = 'reference_number';
    this.sortOrder = 'asc';
    this.columns = complianceListTablecolumns;
    this.quickFilters = complianceListQuickFilters;
    this.moreItemsConfig = complianceListMoreItemsConfig;

    //check  modify permission
    if(this.hasPermission()) this.actionConfig = defaultActionConfig;
  }
  
  hasPermission(type: 'view' | 'modify' | 'manage' = 'modify'): boolean {
    let module: string = 'compliance';
    let subModule: string = 'compliance_register';
    return this.sideMenuStore.hasPermission(module, subModule, type);
  }

  getMasterLists() {
    return forkJoin({
      severityLevels: this.severityLevelService.getSeverityLevels(),
      categories: this.complianceCategoryService.getComplianceCategories(),
      complianceStatuses: this.complianceStatusService.list(),
      complianceReviewStatuses:
        this.complianceReviewStatusService.getComplianceReviewStatuses(),
      reviewFrequencies: this.reviewFrequencyService.getReviewFrequencies(),
      responsibleUsers: this.userService.list('first_name_en', 'asc', 1, 500),
    })
      .pipe(
        tap(
          ({
            severityLevels,
            categories,
            complianceStatuses,
            reviewFrequencies,
            responsibleUsers,
            complianceReviewStatuses,
          }) => {
            runInAction(() => {
              this.severityLevels = severityLevels;
              this.categories = categories;
              this.complianceStatuses = complianceStatuses;
              this.reviewFrequencies = reviewFrequencies;
              this.responsibleUsers = responsibleUsers.items;
              this.complianceReviewStatuses = complianceReviewStatuses;

              this.filterCategories = [
                createFilterCategory(
                  'Severity Level',
                  'severity_level_ids',
                  severityLevels,
                  'title',
                  'en'
                ),
                createFilterCategory(
                  'Category',
                  'compliance_category_ids',
                  categories,
                  'title',
                  'en'
                ),
                createFilterCategory(
                  'Compliance Status',
                  'compliance_status_ids',
                  complianceStatuses,
                  'title',
                  'en'
                ),
                createFilterCategory(
                  'Review Frequency',
                  'review_frequency_ids',
                  reviewFrequencies,
                  'title',
                  'en'
                ),
              ];
            });
          }
        ),
        catchError((error) => {
          console.error('Error fetching master lists', error);
          throw error;
        })
      )
      .subscribe();
  }

  override convertResponseToListDTO(response: any): ComplianceListDTO[] {
    return this.complinaceConversion.resToList(response);
  }

  resetEntireState(): void {
    runInAction(() => {
      this.module = '';
      this.subModule = '';
      this.isInitialLoading = true;
      this.searchQuery = '';
      this.sortField = '';
      this.sortOrder = '';
      this.actionConfig = undefined;
      this.severityLevels = [];
      this.responsibleUsers = [];
      this.categories = [];
      this.complianceStatuses = [];
      this.complianceReviewStatuses = [];
      this.reviewFrequencies = [];
      this.filterCategories = [];
      this.quickFilters = [];
      this.columns = [];
    });
  }
}
